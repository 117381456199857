.prenavbar-div {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 85px;
    display: grid;
    padding: 0px 200px;
    grid-template-columns: 45% 55%;
    align-items: center;
    background-color: white;
}

.prenavbar-mobile-div {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 85px;
    display: grid;
    padding: 10px 2rem;
    grid-template-columns: 65% 25%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid var(--Vertical-Line);
}

.prenavbar-mobile-div .prenavbar-div-left-img {
    width: 50%;
}

.prenavbar-mobile-div .prenavbar-div-right {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}

.prenavbar-div-left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.prenavbar-mobile-div .prenavbar-div-left {
    width: 100%;
    height: 100%;
    display: flex;
}

.prenavbar-div-left-img {
    width: 45%;
    height: auto;
    display: flex;
    align-items: center;
}

.prenavbar-div-left-img img {
    width: 100%;
    height: 100%;
    display: flex;
}

.prenavbar-div-right {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
}

.prenavbar-div-right-individual {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.prenavbar-div-right-individual-left {
    display: flex;
}

.prenavbar-div-right-individual-right ul {
    display: flex;
    flex-direction: column;
    /* gap: 0.1rem; */
    list-style-type: none;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.prenavbar-div-right-individual-right ul li:nth-child(1) {
    font-size: 14px;
    color: var(--Logo-Color4);
}

.prenavbar-div-right-individual-right ul li:nth-child(2) h3 {
    font-size: 16px;
    font-weight: 300;
}

.prenavbar-div-right-individual-btn {
    display: flex;
    width: 100%;
    height: 38px;
    align-items: center;
    justify-content: center;
}

.prenavbar-div-right-individual-btn .appointment-btn {
    cursor: pointer;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--Logo-Color4);
    border-radius: 3px;
    color: var(--Logo-Color4);
    text-decoration: none;
}

.prenavbar-div-right-individual-btn .appointment-btn:hover {
    background-color: var(--Logo-Color4);
    color: white;
}

.prenavbar-drawer ul {
    top: 50px;
    position: relative;
}

/* media query starts */
@media(max-width:1480px) {
    .prenavbar-div {
        padding: 0px 150px;
        grid-template-columns: 25% auto;
    }

    .prenavbar-div-left-img {
        width: 75%;
    }
}

@media(max-width:1061px) {
    .prenavbar-div {
        padding: 0px 75px;
    }

    .prenavbar-div-left-img {
        width: 75%;
    }
}

@media(max-width:940px) {
    .prenavbar-div-right-individual-right ul li:nth-child(1) {
        font-size: 12px;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(2) h3 {
        font-size: 12px;
    }

    .prenavbar-div-right-individual-btn .appointment-btn {
        font-size: 12px;
        width: 100%;
    }

    .prenavbar-div-left-img {
        width: 85%;
    }
}

/* @media(max-width:787px) {
    .prenavbar-div {
        padding: 0px 50px;
    }

    .prenavbar-div-left-img {
        width: 85%;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(1) {
        font-size: 11px;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(2) h3 {
        font-size: 11px;
    }

    .prenavbar-div-right-individual-btn .appointment-btn {
        font-size: 11px;
    }
}

@media(max-width:631px) {
    .prenavbar-div {
        padding: 0px 20px;
    }

    .prenavbar-div-left-img {
        width: 90%;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(1) {
        font-size: 10px;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(2) h3 {
        font-size: 10px;
    }

    .prenavbar-div-right-individual-btn .appointment-btn {
        font-size: 10px;
    }
}
@media(max-width:575px) {
    .prenavbar-div-right
    {
        gap: 0.8rem;
    }
}
@media(max-width:490px) {
    .prenavbar-div {
        padding: 0px;
        height: 45px;
    }
    .prenavbar-div-left-img {
        width: 95%;
    }
    .prenavbar-div-right-individual-right ul li:nth-child(1) {
        font-size: 7px;
    }

    .prenavbar-div-right-individual-right ul li:nth-child(2) h3 {
        font-size: 7px;
    }

    .prenavbar-div-right-individual-btn .appointment-btn {
        font-size: 7px;
        height: auto;
    }
}
@media(max-width:385px) {
    .prenavbar-div-right
    {
        gap: 0.4rem;
    }
    .prenavbar-div-right-individual-right ul li:nth-child(1)
    {
        font-size: 5px;
    }
    .prenavbar-div-right-individual-right ul li:nth-child(2) h3
    {
        font-size: 5px;
    }
    .prenavbar-div-right-individual-btn .appointment-btn{
        font-size: 5px;
    }
} */


/* media query end */
.m-b5489c3c {
    background-color: var(--Logo-Color5);
}

.m-1b7284a3 {
    background-color: var(--Logo-Color5);
}

.m-1b7284a3 ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 16px;
    font-weight: 300;
}

.m-1b7284a3 ul a li {
    width: fit-content;
}

.m-1b7284a3 ul .navbar-link {
    color: white;
    text-decoration: none;
}

.m-1b7284a3 ul li:hover {
    color: var(--Navbar-Link);
    cursor: pointer;
}

.navbar-active {
    color: var(--Navbar-Link);
    text-decoration: none;
}

[data-mantine-color-scheme=light] .m-86a44da5 {
    --_cb-color: #ffffff;
}

.navbar-active,
.navbar-link {
    width: fit-content;
}