.add-review-div {
    font-family: 'Poppins';
    padding: 0px 200px;
}

.add-review-div-container {
    padding: 60px 0px;
}

.add-review-div-container-content {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 98%;
    justify-content: space-between;
    gap: 2rem;
}

.add-review-div-container-content-form-individual {
    padding: 15px 0px;
}

.add-review-div-container-content-form-btn {
    padding: 15px 0px;
}

.add-review-div-container-content-form-btn button {
    cursor: pointer;
    border: 0px;
    width: 150px;
    height: 35px;
    background-color: var(--Logo-Color1);
    color: white;
    border-radius: 3px;
}

.add-review-div-container-content-form-btn button:hover {
    background-color: white;
    color: var(--Logo-Color1);
    border: 1px solid var(--Logo-Color1);
}

.add-review-div-container-content-table {
    width: 100%;
}

.add-review-div-container-content-table .review-show {
    font-family: "Poppins";
    border-collapse: collapse;
    width: 100%;
}

.add-review-div-container-content-table .review-show td,
.add-review-div-container-content-table .review-show th {
    border: 1px solid #ddd;
    padding: 8px;
}

.add-review-div-container-content-table .review-show tr:nth-child(even) {
    background-color: #f2f2f2;
}

.add-review-div-container-content-table .review-show th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    background-color: var(--Logo-Color2);
    color: white;
}

.add-review-div-container-content-table .review-show .review-button
{
    cursor: pointer;
    margin-right: 0.1rem;
    color: var(--Logo-Color1);
}
.add-review-div-container-content-table .review-show .review-button:hover
{
    color: var(--Logo-Color2);
}


/* media query starts */
@media(max-width:1483px) {
    .add-review-div {
        padding: 0px 48px;
    }
}

@media(max-width:1100px) {
    
}

@media(max-width:768px) {
    .add-review-div {
        padding: 0px 1rem;
    }
}
.react-table-pagination {
    margin-top: 1rem;
  }
/* media query ends */