.header-div {
    font-family: "Poppins", Tahoma, Geneva, sans-serif;
    height: 34px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    border-bottom: 1px solid var(--Vertical-Line);
}

.header-div-container-left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 200px;
    color: white;
}

.header-div-container-left ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
    color: var(--Gray-Color);
}

.clock-icon-header {
    display: flex;
}

.header-div-container-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 200px;
    color: white;
    font-size: 12px;
    gap: 2rem;
}

.header-div-container-right ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
    color: var(--Gray-Color);
}

.vertical-line {
    width: 1px;
    height: 60%;
    border-right: 1px solid rgb(182, 182, 182);
}

.header-link a {
    color: white;
    text-decoration: none;
}

.header-link a:hover {
    color: var(--Logo-Color4);
}

.icon-hover {
    transition: color 0.2s;
    cursor: pointer;
}

.icon-hover:hover {
    color: var(--Logo-Color4);
}

/* media query starts */

@media(max-width:1480px) {
    .header-div-container-left {
        padding-left: 150px;
    }

    .header-div-container-right {
        padding-right: 150px;
    }
}

@media(max-width:1061px) {
    .header-div-container-left {
        padding-left: 75px;
    }

    .header-div-container-right {
        padding-right: 75px;
    }
}

@media(max-width:787px) {
    .header-div-container-left {
        padding-left: 50px;
    }

    .header-div-container-right {
        padding-right: 50px;
    }

    .header-div-container-left ul {
        font-size: 10px;
    }

    .header-div-container-right ul {
        font-size: 10px;
    }
}

@media(max-width:631px) {
    .header-div-container-left {
        padding-left: 20px;
    }

    .header-div-container-right {
        padding-right: 20px;
        justify-content: space-between;
        /* gap: 1rem; */
    }

    .header-div-container-left ul {
        font-size: 9px;
    }

    .header-div-container-right ul {
        font-size: 9px;
    }
}

@media(max-width:490px) {
    .header-div-container-left {
        padding-left: 0px;
    }

    .header-div-container-right {
        padding-right: 0px;
        gap: 0rem;
    }

    .header-div-container-left ul {
        font-size: 7px;
    }

    .header-div-container-right ul {
        font-size: 7px;
    }
}

/* media query end */