.bottom-to-top-button {
    z-index: 1;
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    transition: opacity 0.3s ease-in-out;
    animation-name: fallFromTop;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    display: none;
    background-color: var(--Logo-Color5);
}

@keyframes fallFromTop {
    0% {
        transform: translateY(-500%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.bottom-to-top-button.visible {
    display: block;
}

.bottom-to-top-button:hover {
    opacity: 0.8;
}