.home-div {
    width: 100%;
    /* padding: 0px 200px; */
    /* padding: 0px 150px; */
    font-family: 'Poppins';
}

.home-div-container {
    padding: 60px 250px;
    display: flex;
    /* grid-template-columns: 70% 12%; */
    justify-content: space-between;
}

.home-div-container-left {
    display: flex;
    gap: 3rem;
    /* grid-template-columns: 40% 30%; */
}

.home-div-container-left-content {
    width: 421px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
    justify-content: center;
}

.home-div-container-left-content .heading-1 {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    color: var(--Logo-Color4);
}

.home-div-container-left-content .heading-2 {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 0.5rem;
    color: var(--Logo-Color3);
}

.home-div-container-left-content .content-1 {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 100;
    margin-bottom: 0.8rem;
    color: var(--Icon-Color);
}

.content-2-doctor-name {
    text-align: right;
    width: 100%;
}

.content-2-doctor-name-heading-1 {
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    font: 18px Jost, Arial, Helvetica, sans-serif;
}

.content-2-doctor-name-heading-2 {
    color: #6E7379;
    font-weight: 400;
    line-height: 28px;
    font: 14px Jost, Arial, Helvetica, sans-serif;
    text-align: left;
}


.home-div-container-left-image {
    /* width: 100%;
    height: 100%; */
    position: relative;
    width: 349px;
}

.home-div-container-left-image img {
    height: 340px;
    width: 260px;
    border-radius: 18px;
}

.home-div-container-left-image-quote {
    width: 50px;
    height: 50px;
    position: absolute;
    background: linear-gradient(140deg, var(--Logo-Color3), var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light));
    top: 60px;
    left: -25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-div-container-right {
    width: 100%;
    height: 100%;
    position: relative;
}

.home-div-container-right-content {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    z-index: 1;
    width: 395px;
    height: 491px;
    top: -156px;
    background: linear-gradient(140deg, var(--Logo-Color3), var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    position: absolute;
    padding: 45px;
}

.home-div-container-right-content p {
    /* font-size: 16px; */
    line-height: 1.5rem;
    /* word-spacing: 0.3rem; */
    margin-bottom: 20px;
}

.home-div-container-right-content-heading {
    margin-bottom: 20px;
}

.home-div-container-right-content h2 {
    /* font-size: 16px; */
    /* line-height: 2rem; */
    /* word-spacing: 0.3rem; */
    margin-bottom: 20px;
}

.home-div-container-right-content-working-hours {
    display: flex;
    justify-content: space-between;
}

.home-div-container-right-content-working-hours-day ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
}

.home-div-container-right-content-working-hours-day ul li {
    padding: 8px 0px;
}

.home-div-container-right-content-working-hours-day ul li::before {
    position: absolute;
    content: "";
    height: 1px;
    width: auto;
    left: 150px;
    right: 175px;
    margin-top: 10px;
    opacity: 0.20;
    background: #fff;
}

.home-div-container-right-content-working-hours-time ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
}

.home-div-container-right-content-working-hours-time ul li {
    padding: 8px 0px;
}

.our-services {
    padding: 1rem 200px;
}

.our-services-divcontainer-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 0.8rem;
}

.our-services-divcontainer-heading-right p {
    font-size: 14px;
    font-family: 'Poppins';
}

.our-services-divcontainer-heading h4 {
    font-family: "Montserrat";
    font-size: 15px;
    margin-bottom: 10px;
    color: var(--Logo-Color3-Extra-Light);
}

.our-services-divcontainer-heading h1 {
    font-family: "Montserrat";
    font-weight: 300;
    color: var(--Logo-Color3-Light);
}

.our-services-divcontainer-heading-span {
    color: var(--Logo-Color3);
}

.our-services-divcontainer-content {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    justify-content: space-between;
    padding-top: 2rem;
}

.our-services-divcontainer-content-individual {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    width: 100%;
    transition: all 500ms ease-in-out;
    position: relative;
    padding: 1rem 0rem;
}

.our-services-divcontainer-content-individual-image {
    width: 300px;
    height: 300px;
    display: flex;
    /* clip-path: circle(); */
    padding: 1rem;
    border-radius: 50%;

    position: relative;
}

.our-services-divcontainer-content-individual-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rotating-animation-our-services {
    position: absolute;
    width: 15px;
    height: 15px;
    clip-path: circle();
    background-color: var(--Icon-Color);
    /* top: 25px; */
    top: 25px;
    transform-origin: top center;
    display: none;
}

/* @keyframes moveCircular {
    0% {
        transform: rotate(0deg) translateX(50px);
    }

    100% {
        transform: rotate(360deg) translateY(50px);
    }
} */

.our-services-divcontainer-content-individual:hover .rotating-animation-our-services {
    animation: moveCircular 4s linear infinite;
}

.our-services-divcontainer-content-individual:hover .our-services-divcontainer-content-individual-image {
    border: 1px dashed var(--Icon-Color);
}

.our-services-divcontainer-content-individual:hover .rotating-animation-our-services {
    display: flex;
}

.our-services-divcontainer-content-individual-image img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.our-services-divcontainer-content-individual-content {
    color: var(--Icon-Color);
    font-size: 14px;
    /* text-align: justify; */
}

.our-services-divcontainer-content-individual-heading h2 {
    font-size: 18px;
    height: 40px;
}

.our-services-divcontainer-content-individual-content p {
    font-size: 13.5px;
    height: 100px;
    /* text-align: left; */
}

.our-services-divcontainer-content-individual-readmore .readmore-btn {
    background-color: var(--Logo-Color4);
    border-radius: 8px;
}

.our-services-divcontainer-content-individual-readmore .readmore-btn:hover {
    background-color: var(--Logo-Color2);
}

.home-our-counters {
    background-color: var(--Logo-Color3-Light);
    padding: 30px 200px;
    color: white;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
    align-items: center;
}

.home-our-counters-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* gap: 0.3rem; */
    /* align-items: center; */
}

.home-our-counters-div h1 {
    font-size: 50px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.home-our-counters-div p {
    font-size: 18px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: var(--Navbar-Link);
}

.home-testimonials-appoinmentform {
    width: 100%;
    height: 615px;
    padding: 30px 200px;
    background: url('../../Assets/Home/testimonials-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 45% 41%;
    justify-content: space-evenly;
    align-items: center;
}

.home-testimonials-appoinmentform-testimonials {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-testimonials-appoinmentform-form {
    width: 100%;
}

.home-testimonials-appoinmentform-testimonials-heading h4 {
    font-family: "Montserrat";
    font-size: 15px;
    margin-bottom: 10px;
    color: var(--Logo-Color3-Extra-Light);
}

.home-testimonials-appoinmentform-testimonials-heading h1 {
    font-family: "Montserrat";
    font-weight: 300;
    color: var(--Logo-Color3-Light);
}

.home-testimonials-appoinmentform-testimonials-heading-span {
    color: var(--Logo-Color3);
}

.home-testimonials-appoinmentform-testimonials-rating-quote {
    width: 100%;
    margin-top: 0.8rem;
    position: relative;
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 0.8rem;
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review-content p {
    font-size: 14px;
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review-name {
    display: flex;
    gap: 0.5rem;
    /* align-items: center; */
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review-name p {
    color: var(--Logo-Color3);
    font-weight: bold;
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review-name-img {
    width: 75px;
    height: 75px;
    clip-path: circle();
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-testimonials-appoinmentform-testimonials-rating-quote-review-name-img img {
    width: 100%;
    height: 100%;
}

.review-slide {
    background-color: transparent;

}

.review-slider-prev-nxt-btn {
    position: absolute;
    right: 50px;
    bottom: 0px;
    z-index: 1;
    display: flex;
    gap: 0.8rem;
}

.review-slider-prev-nxt-btn button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    background-color: transparent;
    gap: 0.3rem;
    font-size: 14px;
    color: var(--Logo-Color3);
}

.home-testimonials-appoinmentform-form-card {
    width: 100%;
    padding: 50px;
}

.home-testimonials-appoinmentform-form-card-heading {
    /* padding: 10px 30px; */
    margin-bottom: 20px;
}

.home-testimonials-appoinmentform-form-card-heading h2 {
    color: white;
}

/* .home-testimonials-appoinmentform-form-card-content {
    padding: 10px 30px;
} */

.home-testimonials-appoinmentform-form-card-content-form-individual {
    margin-bottom: 20px;
}

.home-testimonials-appoinmentform-form-card-content-form-individual .mantine-Input-input {
    padding: 10px 20px;
    height: 3rem;
}

.home-testimonials-appoinmentform-form-card-content-form-btn {
    margin-bottom: 20px;
}

.home-testimonials-appoinmentform-form-card-content-form-btn button {
    width: 208px;
    height: 46.5px;
    border: 0px;
    background-color: var(--Logo-Color1);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.home-make-appoinment {
    background-color: var(--Logo-Color3-Light);
    padding: 60px 200px;
    color: white;
    display: grid;
    grid-template-columns: 68% 22%;
    justify-content: space-between;
    align-items: center;
}

.home-make-appoinment-left {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.home-make-appoinment-left-icon {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    clip-path: circle();
}

.home-make-appoinment-left-heading h1 {
    font-family: "Montserrat";
    font-weight: 300;
    color: white;
    font-size: 22px;
}

.home-make-appoinment-left-heading-span {
    color: white;
    font-size: 26px;
    font-weight: 700;
}

.home-make-appoinment-right {
    width: 100%;
}

.home-make-appoinment-right button {
    width: 254px;
    height: 47px;
    border: 0px;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--Logo-Color1);
    color: white;
    font-weight: 700;
}

.home-make-appoinment-right button:hover {
    background-color: white;
    color: var(--Logo-Color1);
}

/* media query starts */
@media(max-width:1483px) {
    .home-div-container {
        padding: 60px 5rem;
    }

    .home-div-container-left {
        gap: 0rem;
    }

    .home-div-container-left-image {
        width: 280px;
    }

    .home-div-container-right-content {
        padding: 30px;
        width: 340px;
        height: 515px;
    }

    .our-services {
        padding: 1rem 48px;
    }

    .our-services-divcontainer-content-individual-image {
        width: 250px;
        height: 250px;
    }

    .home-our-counters {
        padding: 1rem 48px;
    }

    .home-testimonials-appoinmentform {
        padding: 1rem 48px;
        height: auto;
        justify-content: space-between;
    }
}

@media(max-width:1100px) {
    .home-div-container-right-content {
        top: 0px;
        width: 100%;
        position: relative;
        transform: translateX(0);
        margin: auto;
        height: 100%;
    }

    .home-div-container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .our-services-divcontainer-heading h4 {
        width: fit-content;
        margin: auto;
        padding-bottom: 15px;
    }

    .our-services-divcontainer-heading h1 {
        width: fit-content;
        margin: auto;
        /* padding-bottom: 15px; */
    }

    .our-services-divcontainer-heading-right p {
        width: fit-content;
        margin: auto;
    }

    .our-services-divcontainer-content {
        grid-template-columns: 48% 48%;
    }

    .our-services-divcontainer-content-individual-image {
        width: 300px;
        height: 300px;
    }

    .home-testimonials-appoinmentform {
        grid-template-columns: 48% 48%;
    }
}

@media(max-width:768px) {
    .home-div-container {
        padding: 1rem 1rem;
        width: 100%;
    }

    .home-div-container-left {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    .our-services-divcontainer-content {
        grid-template-columns: 100%;
    }

    .our-services {
        padding: 1rem 1rem;
    }

    .home-our-counters {
        padding: 1rem 1rem;
        grid-template-columns: 100%;
        gap: 1rem;
    }

    .home-testimonials-appoinmentform {
        grid-template-columns: 100%;
        gap: 1.5rem;
        padding: 1rem;
    }

}

@media(max-width:450px) {
    .home-div-container-left-content {
        width: 100%;
    }

    .content-2-doctor-name-heading-1 {
        text-align: center;
        width: fit-content;
        margin: auto;
        font-size: 12px;
    }

    .home-div-container-left-content .content-1 {
        font-size: 14px;
        width: auto;
        margin: auto;
        padding-bottom: 0.3rem;
    }

    .home-div-container-left-content .heading-1 {
        width: fit-content;
        margin: auto;
        padding-bottom: 0.3rem;

    }

    .home-div-container-left-content .heading-2 {
        font-size: 32px;
        line-height: 30px;
        width: fit-content;
        margin: auto;
        padding-bottom: 0.3rem;
    }

    .our-services-divcontainer-content-individual-image {
        width: 200px;
        height: 200px;
    }

    .home-testimonials-appoinmentform-form-card {
        padding: 25px;
    }

    .home-testimonials-appoinmentform-form-card-content-form-btn button {
        width: 100%;
    }

    .review-slider-prev-nxt-btn {
        position: relative;
        left: 0;
        top: 10px;
    }

}

/* media query ends */