.gallery-div {
    font-family: 'Poppins';
    padding: 0px 200px;
}

.gallery-div-heading {
    font-family: 'Montserrat';
    margin-bottom: 15px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-div-content {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    padding-bottom: 60px;
}

.gallery-div-content-individual {
    width: 100%;
    height: 300px;
    cursor: pointer;
    margin-bottom: 55px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    transition: transform 0.3s, filter 0.3s;
    ;
}

.gallery-div-content-individual img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s, filter 0.3s;
}

.gallery-div-content-individual:hover {
    transform: scale(1.05);
    filter: brightness(0.8);
}

.gallery-div-content-individual img:hover {
    transform: scale(1.05);
    filter: brightness(0.8);
}

/* media query starts */
@media(max-width:1483px) {
    .gallery-div {
        padding: 0px 48px;
    }
}

@media(max-width:1100px) {
    .gallery-div-content {
        grid-template-columns: 48% 48%;
    }
}

@media(max-width:768px) {
    .gallery-div {
        padding: 0px 1rem;
    }

    .gallery-div-content {
        grid-template-columns: 98%;
    }
}

/* media query ends */