.footer-div {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    /* height: 612px; */
    display: grid;
    grid-template-columns: 100%;
    /* background-image: url('../../Assets/Footer/footer-bg.webp'); */
    background-repeat: no-repeat;
    background-size: cover;

}

.footer-div-main-footer {
    width: 100%;
    /* height: 446px; */
    padding: 30px 200px;
    background-color: var(--Footer-Color);
    opacity: 0.8;
    display: grid;
    grid-template-columns: 22% 18% 23% 22%;
    justify-content: space-between;
}

.footer-div-newsletter {
    width: 100%;
    /* height: 105px; */
    padding: 0px 130px;
    background-color: var(--Footer-Color);
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.footer-div-newsletter-form {
    width: 100%;
    display: flex;
}

.footer-div-newsletter-form input {
    width: 420px;
    /* height: 45px; */
    padding-left: 0.5rem;
    border-radius: 26px;
    border: 0px;
}

.footer-div-newsletter-form button {
    width: 121.7px;
    /* height: 45px; */
    border: 0px;
    background-color: var(--Logo-Color4);
    color: white;
    margin-left: -120px;
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
    cursor: pointer;
}

.footer-div-copyright {
    width: 100%;
    /* height: 61px; */
    padding: 0px 130px;
    background-color: var(--Footer-Color);
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.footer-div-main-footer-ourdetails {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: #000000;
    font-size: 16px;
}

.footer-div-copyright-container {
    padding: 20px 0px;
}

.footer-div-main-footer-ourdetails-logo {
    width: 80%;
    height: auto;
}

.footer-div-main-footer-ourdetails-logo img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.footer-div-main-footer-ourdetails ul {
    list-style-type: none;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    font-size: 14px;
}

.heading-underline {
    width: 75px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 8px 0px;
}

.footer-div-main-footer-departments {
    width: 100%;
    height: 100%;
    color: var(--Logo-Color3);
}

.footer-div-main-footer-departments-content ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
}

.footer-div-main-footer-departments-content ul li {
    cursor: pointer;
    width: fit-content;
    color: #000000;
}

.footer-div-main-footer-departments-content ul li:hover {
    color: var(--Logo-Color4);
}

.fotter-link {
    color: #000000;
    text-decoration: none;
}

.fotter-link:hover {
    color: var(--Logo-Color4);
}

.footer-div-main-footer-quicklinks {
    width: 100%;
    height: 100%;
    color: var(--Logo-Color3);
}

.footer-div-main-footer-quicklinks-content ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
}

.footer-div-main-footer-quicklinks-content ul li {
    cursor: pointer;
    width: fit-content;
    color: #000000;
}

.footer-div-main-footer-quicklinks-content ul li:hover {
    color: var(--Logo-Color4);
}

.footer-div-main-footer-openinghours {
    width: 100%;
    height: 100%;
    color: var(--Logo-Color3);
}

.footer-div-main-footer-openinghours-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
}

.footer-div-main-footer-openinghours-content ul {
    display: flex;
    gap: 1rem;
}

.footer-div-main-footer-openinghours-content ul li {
    list-style-type: none;
    display: flex;
    gap: 1rem;
    color: #000000;
}

.footer-link a {
    text-decoration: none;
    color: #000000;
}

.footer-link a:hover {
    color: var(--Logo-Color4);
}

/* .footer-div-main-footer-openinghours-content-day ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
}

.footer-div-main-footer-openinghours-content-time ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
    justify-content: flex-end;
} */

.footer-div-newsletter-social-links {
    display: flex;
    gap: 0.8rem;
    /* justify-content: flex-end; */
}

.footer-div-newsletter-social-links-individual {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--Logo-Color4);
    cursor: pointer;
}

.footer-div-newsletter-social-links-individual a {
    height: 22px;
}

.footer-div-newsletter-social-links-individual:hover {
    background-color: var(--Logo-Color3-Light);
}

.footer-div-copyright .footer-link {
    text-decoration: none;
    color: #000000;
}

/* media query starts */
@media(max-width:1483px) {
    .footer-div-main-footer {
        padding: 30px 48px;
    }

    .footer-div-copyright {
        padding: 0px 48px;
    }

    .home-make-appoinment {
        padding: 60px 48px;
    }
}

@media(max-width:1100px) {
    .footer-div-main-footer {
        grid-template-columns: 48% 48%;
        gap: 1.5rem;
    }

    .home-make-appoinment {
        grid-template-columns: 68% 32%;
    }
}

@media(max-width:768px) {

    .footer-div-main-footer {
        padding: 1rem;
        grid-template-columns: 100%;
    }

    .home-make-appoinment {
        grid-template-columns: 100%;
        gap: 1.5rem;
        padding: 1rem;
    }
}

/* media query ends */