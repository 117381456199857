.contactus-div {
    font-family: 'Poppins';
    padding: 60px 200px;
}

.contactus-div-form-opentimings {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
    align-items: center;
}

.contactus-div-container-right-content {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    z-index: 1;
    width: 100%;
    height: 491px;
    top: -156px;
    background: linear-gradient(140deg, var(--Logo-Color3), var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    /* position: absolute; */
    padding: 45px;
}

.contactus-div-container-right-content p {
    /* font-size: 16px; */
    line-height: 1.5rem;
    /* word-spacing: 0.3rem; */
    margin-bottom: 20px;
}

.contactus-div-container-right-content-heading {
    margin-bottom: 20px;
}

.contactus-div-container-right-content h2 {
    /* font-size: 16px; */
    /* line-height: 2rem; */
    /* word-spacing: 0.3rem; */
    margin-bottom: 20px;
}

.contactus-div-container-right-content-working-hours {
    display: flex;
    justify-content: space-between;
}

.contactus-div-container-right-content-working-hours-day ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
}

.contactus-div-container-right-content-working-hours-day ul li {
    padding: 8px 0px;
}

.contactus-div-container-right-content-working-hours-day ul li::before {
    position: absolute;
    content: "";
    height: 1px;
    width: auto;
    left: 350px;
    right: 62%;
    margin-top: 10px;
    opacity: 0.20;
    background: #fff;
}

.contactus-div-container-right-content-working-hours-time ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
}

.contactus-div-container-right-content-working-hours-time ul li {
    padding: 8px 0px;
}

.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 550px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 550px;
}

.gmap_canvas a {
    display: none;
}

.gmap_iframe {
    height: 500px !important;
}

.contactus-div-form {
    padding: 25px;
}

.contactus-div-form-heading {
    text-align: center;
    font-family: 'Montserrat';
}

.contactus-div-form-individual {
    margin: 15px 0px;
}

.contactus-div-form-btn button {
    cursor: pointer;
    border: 0px;
    border-radius: 3px;
    background-color: var(--Logo-Color4);
    color: white;
    width: 200px;
    height: 40px;
}

.contactus-div-form-btn button:hover {
    background-color: var(--Logo-Color3);
}

/* media query starts */
@media(max-width:1483px) {
    .contactus-div {
        padding: 60px 48px;
    }

    .contactus-div-container-right-content-working-hours-day ul li::before {
        left: 200px;
        right: 64%;
    }
}

@media(max-width:1100px) {
    .contactus-div-form-opentimings {
        grid-template-columns: 100%;
    }

    .contactus-div-container-right-content-working-hours-day ul li::before {
        left: 200px;
        right: 22%;
    }
}

@media(max-width:768px) {
    .contactus-div {
        padding: 60px 1rem;
    }

    .contactus-div-container-right-content-working-hours-day ul li::before {
        left: 200px;
        right: 28%;
    }
}

@media(max-width:450px) {

    .contactus-div-container-right-content-working-hours-day ul li::before {
        left: 175px;
        right: 45%;
    }
}

/* media query ends */