.homeslider-div {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    height: 690px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-div1 {
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/HomeSlider/image1.webp');
    background-repeat: no-repeat;
    background-size: cover;
    /* transform: scaleX(1.5); */
}

.slider-div2 {
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/HomeSlider/image2.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.slider-div-image {
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: linear-gradient(to left, var(--Logo-Color3-Light), var(--Logo-Color3-Light), var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light), var(--Logo-Color3-Extra-Light));
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 12rem;
}

.slider-div-image-content {
    display: flex;
    flex-direction: column;
    /* gap: 0.8rem; */
    /* padding-left: 10rem; */
    transform: scale(1.2);

}

.slider-div-image-heading {
    width: 290px;
    height: 28px;
    background-color: white;
    color: var(--Logo-Color1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-div2-image-heading {
    width: 268px;
    height: 28px;
    background-color: white;
    color: var(--Logo-Color1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-div-image-heading p {
    letter-spacing: 0.2rem;
    /* font-weight: 500; */
}

.slider-div-image-para p {
    text-transform: capitalize;
    font-size: 20px;
}

.slider-div2-image-para p {
    text-transform: capitalize;
    font-size: 20px;
    padding: 0.5rem 0rem;
}

.slider-div2-image {
    width: 100%;
    height: 100%;
    /* background-color: var(--Logo-Color3-Light); */
    opacity: 1;
    background: linear-gradient(to right, var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light), transparent);
    color: white;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    /* padding-right: 10rem; */
}

.slider-div2-image-content {
    display: flex;
    flex-direction: column;
    /* gap: 0.8rem; */
    padding-left: 10rem;
    transform: scale(1.1);
}

.swiper-button-prev:after {
    margin-left: 2rem;
    font-size: 16px !important;
    font-weight: bold;
    color: white;
    background-color: var(--Logo-Color3);
    padding: 1rem;
    clip-path: circle();
}

.swiper-button-next:after {
    margin-right: 2rem;
    font-size: 16px !important;
    font-weight: bold;
    color: white;
    background-color: var(--Logo-Color3);
    padding: 1rem;
    clip-path: circle();
}

.slider-div-image-sub-heading h1 {
    font-size: 58px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.5rem;
}

.slider-div-image-sub-heading-fontstyle {
    font-family: "Montserrat";
    font-weight: 300;
}

.slider-div2-image-sub-heading h1 {
    font-size: 54px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.5rem;
}

.slider-div2-image-sub-heading-fontstyle {
    font-family: "Montserrat";
    font-weight: 300;
    color: rgb(190, 190, 190);
}

.color-slider {
    color: rgb(190, 190, 190);
}

.slider-div2 .slider-div-image-content {
    padding-left: 10rem;
    padding-bottom: 5rem;
}

.slider-div-image-icons {
    display: flex;
    gap: 0.8rem;
}

.slider-div-image-icons-individual {
    width: 60px;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: circle();
}

.slider-div-image-icons-individual img {
    width: 60%;
    height: auto;
}

.slider-div2-image-icons {
    display: flex;
    gap: 0.8rem;
    padding: 0.5rem 0rem;
}

.slider-div2-image-icons-individual {
    width: 45px;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: circle();
    cursor: pointer;
    transition: all 500ms ease-in-out;
}

.slider-div2-image-icons-individual img {
    width: 60%;
    height: auto;
}

.slider-div2-image-icons-individual:hover {
    transform: scale(1.2);
}

@media(max-width:1200px) {
    .homeslider-div {
        height: 500px;
    }

    .slider-div2-image-content {
        padding-left: 4rem;
    }

    .slider-div-image {
        padding-right: 4rem;
    }

    .slider-div2-image-heading {
        height: 22px;
    }

    .slider-div2-image-sub-heading h1 {
        font-size: 46px;
    }

    .slider-div2-image-para p {
        font-size: 18px;
    }
}

@media(max-width:1203px) {
    .homeslider-div {
        height: 560px;
    }
}

@media(max-width:1060px) {
    .slider-div2-image-content {
        padding-left: 4rem;
    }

    .homeslider-div {
        height: 400px;
    }

    .slider-div-image-heading {
        height: 22px;
    }

    .slider-div-image-sub-heading h1 {
        font-size: 42px;
    }

    .slider-div2-image-para p {
        font-size: 16px;
    }

    .slider-div2-image-heading {
        height: 22px;
    }

    .slider-div2-image-sub-heading h1 {
        font-size: 42px;
    }

    .slider-div2-image-para p {
        font-size: 16px;
    }
}

@media(max-width:786px) {
    .homeslider-div {
        height: 300px;
    }

    .slider-div-image-heading {
        width: 220px;
        font-size: 14px;
    }

    .slider-div-image-sub-heading h1 {
        font-size: 44px;
    }

    .slider-div2-image-para p {
        font-size: 14px;
    }

    .slider-div2-image-heading {
        width: 220px;
        font-size: 14px;
    }

    .slider-div2-image-sub-heading h1 {
        font-size: 34px;
    }

    .slider-div2-image-para p {
        font-size: 18px;
    }

    .slider-div2-image {
        justify-content: center;
    }
}

@media(max-width:500px) {
    .slider-div2-image-content {
        padding-left: 0rem;
        padding-right: 1rem;
    }

    .slider-div-image-content {
        padding-right: 0rem;
    }

    .slider-div-image {
        padding-right: 1rem;
        justify-content: center;
    }

    .homeslider-div {
        height: 40vh;
    }

    .swiper-button-next:after {
        padding: 0.5rem;
    }

    .swiper-button-prev:after {
        padding: 0.5rem;
    }

    .slider-div-image-heading {
        width: 200px;
        font-size: 12px;
    }

    .slider-div2-image-heading {
        width: 200px;
        font-size: 12px;
    }

    .slider-div-image-sub-heading h1 {
        font-size: 30px;
    }

    .slider-div2-image-sub-heading h1 {
        font-size: 22px;
    }

    .slider-div2-image-para p {
        font-size: 12px;
    }

    .slider-div-image-para p {
        font-size: 12px;
    }

    .slider-div2-image-icons-individual {
        width: 30px;
        height: 30px;
    }
}