.services-div {
    font-family: 'Poppins';
}

.service-underline {
    width: 75px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 8px 0px;
}

.services-div-banner {
    width: 100%;
    height: 550px;
    /* position: relative; */
    background: url('../../Assets/Services/service.webp');
    background-position: center;
    display: flex;
    align-items: center;
}

.services-banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-right: 200px;
}

.services-banner-content h1 {
    font-family: 'Montserrat';
    font-size: 50px;
    font-weight: 300;
}

.services-banner-content ul {
    list-style-type: none;
    display: flex;
    gap: 0.5rem;
}

.services-banner-content ul li a {
    text-decoration: none;
    color: black;
}

.services-div-content-checkup {
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    padding: 60px 200px;
    /* background-color: #464D77; */
    /* color: white; */
}

.services-div-content-checkup-left {
    width: 100%;
    /* clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%); */

}

@keyframes border-transform-default {

    0%,
    to {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }
}

.services-div-content-checkup-left img {
    width: 100%;
    height: 100%;
    -webkit-animation: border-transform-default 10s linear infinite alternate forwards;
    animation: border-transform-default 10s linear infinite alternate forwards;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-div-content-checkup-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.services-div-content-checkup-right-heading {
    font-family: 'Montserrat';
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.services-div-content-checkup-right-heading h2::after {
    content: '';
    display: flex;
    width: 250px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 0.5rem auto;
}

.services-div-content-checkup-right-content p {
    font-size: 13.5px;
    text-align: center;
    width: 80%;
    margin: auto;
}

.services-div-content-glaucoma-services {
    display: grid;
    grid-template-columns: 65% 30%;
    justify-content: space-between;
    padding: 60px 200px;
    /* background-color: #e6177b72; */
    /* background-color: lightgray; */
    background-color: #f4f6fa;
    /* color: white; */
}

.services-div-content-glaucoma-services-left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.services-div-content-glaucoma-services-left-heading {
    font-family: 'Montserrat';
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.services-div-content-glaucoma-services-left-heading h2::after {
    content: '';
    display: flex;
    width: 200px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 0.5rem auto;
}

.services-div-content-glaucoma-services-left-content p {
    font-size: 13.5px;
    text-align: center;
    width: 80%;
    margin: auto;
}

.services-div-content-glaucoma-services-right {
    width: 100%;
    /* clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%); */
}

.services-div-content-glaucoma-services-right img {
    width: 100%;
    height: 100%;
    -webkit-animation: border-transform-default 10s linear infinite alternate forwards;
    animation: border-transform-default 10s linear infinite alternate forwards;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-div-content-cataract-services {
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    padding: 60px 200px;
    /* background-color: #8e2a811b; */
    background-color: rgba(255, 255, 224, 0.245);
}

.services-div-content-cataract-services-left {
    width: 100%;
    /* clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%); */
}

.services-div-content-cataract-services-left img {
    width: 100%;
    height: 100%;
    -webkit-animation: border-transform-default 10s linear infinite alternate forwards;
    animation: border-transform-default 10s linear infinite alternate forwards;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-div-content-cataract-services-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.services-div-content-cataract-services-right-heading {
    text-align: center;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
}

.services-div-content-cataract-services-right-heading h2::after {
    content: '';
    display: flex;
    width: 200px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 0.5rem auto;
}

.services-div-content-cataract-services-right-content p {
    font-size: 13.5px;
    text-align: center;
    width: 80%;
    margin: auto;
}

.services-div-content-retina-services {
    display: grid;
    grid-template-columns: 65% 30%;
    justify-content: space-between;
    padding: 60px 200px;
    /* background-color: #321d552e; */
    background-color: #f4eded55;
}

.services-div-content-retina-services-right {
    width: 100%;
    /* clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%); */
}

.services-div-content-retina-services-right img {
    width: 100%;
    /* height: 100%; */
    -webkit-animation: border-transform-default 10s linear infinite alternate forwards;
    animation: border-transform-default 10s linear infinite alternate forwards;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-div-content-retina-services-left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.services-div-content-retina-services-left-heading {
    font-family: 'Montserrat';
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.services-div-content-retina-services-left-heading h2::after {
    content: '';
    display: flex;
    width: 175px;
    height: 2px;
    background-color: var(--Logo-Color1);
    margin: 0.5rem auto;
}

.services-div-content-retina-services-left-content p {
    font-size: 13.5px;
    text-align: center;
    width: 80%;
    margin: auto;
}

/* media query starts */
@media(max-width:1483px) {
    .services-div-content-checkup {
        padding: 60px 48px;
    }

    .services-div-content-glaucoma-services {
        padding: 60px 48px;
    }

    .services-div-content-cataract-services {
        padding: 60px 48px;
    }

    .services-div-content-retina-services {
        padding: 60px 48px;
    }
}

@media(max-width:1100px) {
    .services-banner-content {
        padding-right: 50px;
    }
}

@media(max-width:768px) {
    .services-div-content-checkup {
        padding: 60px 1rem;
        grid-template-columns: 100%;
        gap: 2rem;
    }

    .services-div-banner {
        height: 40vh;
    }

    .services-div-content-checkup-left,
    .services-div-content-glaucoma-services-right,
    .services-div-content-cataract-services,
    .services-div-content-retina-services-right {
        width: 75%;
        margin: auto;
    }

    .services-div-content-glaucoma-services {
        padding: 60px 1rem;
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .services-div-content-cataract-services {
        padding: 60px 1rem;
        grid-template-columns: 100%;
        gap: 2rem;
    }

    .services-div-content-retina-services {
        padding: 60px 1rem;
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }
}

@media(max-width:450px) {

    .services-div-banner {
        position: relative;
    }

    .services-banner-content {
        padding-right: 0px;
        position: absolute;
        top: 25px;
        right: 25px;
    }
}

/* media query ends */