.navbar-div {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    width: 100%;
    height: 40px;
    display: grid;
    padding: 0px 130px;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--Logo-Color5);
    position: sticky;
    top: 0px;
}

.navbar-div-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-div-right ul {
    list-style-type: none;
    display: flex;
    gap: 4rem;
    font-size: 16px;
    font-weight: 300;
}

.navbar-div-right ul li {
    width: fit-content;
}

.navbar-div-right ul .navbar-link {
    color: white;
    text-decoration: none;
}

.navbar-div-right ul li:hover {
    color: var(--Navbar-Link);
    cursor: pointer;
}

.navbar-active {
    color: var(--Navbar-Link);
    text-decoration: none;
}

@media(max-width:870px) {
    .navbar-div {
        padding: 0px 75px;
    }
}

@media(max-width:787px) {
    .navbar-div {
        padding: 0px 50px;
    }

    .navbar-div-right ul {
        font-size: 13px;
    }
}

@media(max-width:631px) {
    .navbar-div {
        padding: 0px 20px;
    }

    .navbar-div-right ul {
        font-size: 13px;
    }
}

@media(max-width:575px) {
    .navbar-div-right ul {
        font-size: 10px;
        gap: 1.5rem;
    }
}

@media(max-width:385px) {
    .navbar-div-right ul {
        font-size: 8px;
        gap: 1.5rem;
    }
}