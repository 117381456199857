.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12345678;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-content img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.modal-content .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
}