.whatsapp-button {
    z-index: 1;
    display: block;
    position: fixed;
    bottom: 10px;
    left: 10px;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    transition: opacity 0.3s ease-in-out;
    animation-name: fallFromTop;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    background-color: #25d366;
}

@keyframes fallFromTop {
    0% {
        transform: translateY(-500%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}