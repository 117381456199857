@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

:root {
    --Logo-Color1: #e6177b;
    --Logo-Color5: #8e2a80;
    --Navbar-Link: #cdc671;
    --Logo-Color2: #702e80;
    --Logo-Color3: #321d55;
    --Logo-Color3-Light: #583b86;
    --Logo-Color3-Extra-Light: #583b86c9;
    --Logo-Color4: #de2679;
    --Vertical-Line: #B6B6B6;
    --Gray-Color: #00000099;
    --Icon-Color: #42464e;
    --Footer-Color: #F4F4F4;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* sticky header */

.sticky-navbar {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 12345677;
}

@media (max-width:787px) {
    .md-q787 {
        display: none;
    }
}