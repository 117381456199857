.about-div {
    font-family: 'Poppins';
}

.about-div-banner {
    width: 100%;
    height: 550px;
    /* position: relative; */
    background: url('../../Assets/About/about.webp');
    background-position: center;
    display: flex;
    align-items: center;
}

.about-div-banner img {
    width: 100%;
}

.about-banner-content {
    padding: 180px 250px;
}

.about-banner-content h1 {
    font-family: 'Montserrat';
    font-size: 50px;
    font-weight: 300;
}

.about-banner-content ul {
    list-style-type: none;
    display: flex;
    gap: 0.5rem;
}

.about-banner-content ul li a {
    text-decoration: none;
    color: black;
}

.about-div-content {
    display: grid;
    grid-template-columns: 30% 60%;
    justify-content: space-between;
    background: url('../../Assets/About/about_bg.webp');
    background-position: center;
    background-size: contain;
}

.about-div-content-image {
    width: 100%;
    padding-left: 200px;
    display: grid;
    align-items: flex-end;
}

.about-div-content-image-podium {
    width: 400px;
    height: 70px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: linear-gradient(165deg, var(--Logo-Color3), var(--Logo-Color3-Light), var(--Logo-Color3-Extra-Light));
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Montserrat';
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.about-div-content-image img {
    width: 400px;
    height: auto;
    margin-bottom: -60px;
    /* margin-left: -6px; */
}

.about-div-content-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 200px;
}

.about-div-content-content p {
    font-size: 13.5px;
}

.about-div-content-content .our-services-divcontainer-heading-left {
    font-family: 'Montserrat';
}

.about-div-content-content .our-services-divcontainer-heading-left h4 {
    font-weight: 300;
}

.about-div-content-content .our-services-divcontainer-heading-left h1 {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 40px;
}

.about-div-content-content .our-services-divcontainer-heading-left .our-services-divcontainer-heading-span {
    font-weight: 700;
}

.about-div-content-vision {
    padding: 80px 200px;
    /* background-color: #de267912; */
    background-color: #f4ffeb89;
}

.about-div-content-vision-heading {
    text-align: center;
    margin-bottom: 50px;
}

.about-div-content-vision-heading h1 {
    font-family: "Montserrat";
    font-weight: 300;
    color: var(--Logo-Color3-Light);
    font-size: 36px;
    line-height: 2.5rem;
    margin-bottom: 10px;
}

.about-div-content-vision-heading h4 {
    font-family: "Montserrat";
    font-size: 15px;
    margin-bottom: 10px;
    color: var(--Logo-Color3-Extra-Light);
}

.about-div-content-vision-content {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}

.about-div-content-vision-content-individual {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.about-div-content-vision-content-individual-image {
    width: 60px;
    height: 61px;
}

.about-div-content-vision-content-individual-image img {
    width: 100%;
    height: 100%;
}

.about-div-content-vision-content-individual-heading h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin: 8px 0 20px 0;
    font-family: "Montserrat";
}

.about-div-content-vision-content-individual-content p {
    font-size: 14px;
    margin-bottom: 20px;
    font-family: "Poppins";
}

/* media query starts */
@media(max-width:1483px) {
    .about-div-content-image {
        padding-left: 48px;
    }

    .about-div-content-content {
        padding-right: 48px;
    }

    .about-div-content-vision {
        padding: 80px 48px;
    }
}

@media(max-width:1100px) {
    .about-div-content-image {
        padding-left: 1rem;
        justify-content: center;
    }

    .about-div-content-content {
        padding-right: 1rem;
        text-align: center;
    }

    .about-div-content-vision {
        padding: 80px 1rem;
    }

    .about-div-content {
        grid-template-columns: 90%;
        justify-content: center;
    }

    .about-div-content-vision-content {
        grid-template-columns: 48% 48%;
    }
}

@media(max-width:768px) {
    .about-div-content-vision-content {
        grid-template-columns: 100%;
    }

    .about-div-banner {
        height: 50vh;
    }

    .about-banner-content {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 0px 20px;

    }

    .about-div-content-content .our-services-divcontainer-heading-left h4 {
        width: fit-content;
        margin: auto;
    }

    .about-div-content-content .our-services-divcontainer-heading-left h1 {
        width: fit-content;
        margin: auto;
    }

    .about-div-content-image-podium {
        width: 100%;
    }

    .about-div-content-image img {
        width: 100%;
    }

    .about-div-content-vision-heading h4,
    .about-div-content-vision-heading h1 {
        width: fit-content;
        margin: auto;
        padding-bottom: 15px;
    }

    .about-div-content-vision-content-individual-content {
        width: 100%;
    }

    .about-div-content-vision-content-individual-content p {
        width: fit-content;
        margin: auto;
        padding-bottom: 20px;
    }

}

/* media query ends */